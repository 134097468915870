/* eslint-disable */
const initialState = {
    isOpen : false
};

const notificationBar = (state = initialState, action) => {
  switch (action.type) {
    case "SET FALSE":
      return (state = {
        isOpen : action.value
      });
    default:
      return state;
  }
};
export default notificationBar;
