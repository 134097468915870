/* eslint-disable */

// ** React Imports
import ReactDOM from "react-dom";
import { Suspense, lazy } from "react";
import { BrowserRouter, useNavigate } from "react-router-dom";

// ** GraphQL Apollo Client
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  from,
  useApolloClient,


  split, HttpLink
} from "@apollo/client";
import { createUploadLink } from "apollo-upload-client";
import { onError } from "@apollo/client/link/error";

//asssssssss

import { getMainDefinition } from '@apollo/client/utilities';

import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';


//asssssssssss


// import { ApolloLink, concat, split } from "apollo-link";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
const MySwal = withReactContent(Swal);

// ** Redux Imports
import { store } from "./redux/store";
import { Provider } from "react-redux";

// ** Intl, CASL & ThemeColors Context
import ability from "./configs/acl/ability";
import { AbilityContext } from "./utility/context/Can";
import { ThemeContext } from "./utility/context/ThemeColors";
import "semantic-ui-css/semantic.min.css";

// ** ThemeConfig
import themeConfig from "./configs/themeConfig";

// ** Toast
import toast, { Toaster } from "react-hot-toast";
import { setContext } from "@apollo/client/link/context";

// ** i18n
import "./configs/i18n";

// ** Spinner (Splash Screen)
import Spinner from "./@core/components/spinner/Fallback-spinner";

// ** Ripple Button
import "./@core/components/ripple-button";

// ** Fake Database
import "./@fake-db";

// ** PrismJS
import "prismjs";
import "prismjs/themes/prism-tomorrow.css";
import "prismjs/components/prism-jsx.min";

// ** React Perfect Scrollbar
import "react-perfect-scrollbar/dist/css/styles.css";

// ** React Hot Toast Styles
import "@styles/react/libs/react-hot-toasts/react-hot-toasts.scss";

// ** Core styles
import "./@core/assets/fonts/feather/iconfont.css";
import "./@core/scss/core.scss";
import "./assets/scss/style.scss";
import "./index.scss";

// ** Service Worker
import * as serviceWorker from "./serviceWorker";

const errorLink = onError(({ graphQLErrors, networkError, forward }) => {
  if (graphQLErrors) {
    console.log("GraphlQL Error", graphQLErrors[0]);
    if (JSON.parse(graphQLErrors[0].message).statusCode === 401) {
      localStorage.clear();
      // localStorage.setItem('i18nextLng', 'en')
      client.clearStore();
      window.location.replace(`${window.location.origin}/login`);
    }

    if (
      process.env.REACT_APP_ENV === "production" ||
      process.env.REACT_APP_ENV === "staging"
    ) {
      MySwal.fire({
        title: "Error!",
        text: JSON.parse(graphQLErrors[0].message).message,
        icon: "error",
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      });
    }

    return forward();
  }

  if (networkError) {
    console.log("Network Error", networkError);

    if (
      process.env.REACT_APP_ENV === "production" ||
      process.env.REACT_APP_ENV === "staging"
    ) {
      //handle network error
      MySwal.fire({
        title: "Error!",
        text: networkError.message,
        icon: "error",
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      });
    }

    return forward();
  }
});

const uploadLink = createUploadLink({
  uri: process.env.REACT_APP_ENDPOINT,
  // headers: {
  //   authorization: localStorage.getItem('@apptoken')
  // }
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem("@apptoken");
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token,
      "Content-Type": "application/json",
      "apollo-require-preflight": "true",
      "x-apollo-operation-name": "myOperation",
    },
  };
});

// const wsLink = new GraphQLWsLink(createClient({
//   url: process.env.REACT_APP_ENDPOINT_SOCKET,
//   connectionParams: {
//     authToken: localStorage.getItem("@apptoken"),
//   },
// }));

// var client = new ApolloClient({
//   link: from([errorLink, authLink.concat(uploadLink)]),
//   cache: new InMemoryCache({
//     addTypename: false,
//   }),
//   defaultOptions: {
//     query: {
//       fetchPolicy: "network-only",
//     },
//     watchQuery: { fetchPolicy: "no-cache" },
//   },
// });


//assssssssssssss


// const httpLink = new HttpLink({
//   uri: process.env.REACT_APP_ENDPOINT,
// });

const wsLink = new GraphQLWsLink(createClient({
  url: process.env.REACT_APP_ENDPOINT_SOCKET,
  connectionParams: {
    Authorization: localStorage.getItem("@apptoken"),
  },
}));

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsLink,
  // authLink.concat(httpLink),
  authLink.concat(uploadLink),
);


var client = new ApolloClient({
  link: splitLink,
  cache: new InMemoryCache({
    addTypename: false,
  }),
  defaultOptions: {
    query: {
      fetchPolicy: "network-only",
    },
    watchQuery: { fetchPolicy: "no-cache" },
  },
});
//asssssssssssss

// if (
//   process.env.REACT_APP_ENV === "production" ||
//   process.env.REACT_APP_ENV === "staging"
// ) {
if (process.env.REACT_APP_ENV === "production") {
  console.log = () => { };
  console.error = () => { };
  console.debug = () => { };
}

// ** Lazy load app
const LazyApp = lazy(() => import("./App"));
ReactDOM.render(
  <ApolloProvider client={client}>
    <BrowserRouter>
      <Provider store={store}>
        <Suspense fallback={<Spinner />}>
          <AbilityContext.Provider value={ability}>
            <ThemeContext>
              <LazyApp />
              <Toaster
                position={themeConfig.layout.toastPosition}
                toastOptions={{ className: "react-hot-toast" }}
              />
            </ThemeContext>
          </AbilityContext.Provider>
        </Suspense>
      </Provider>
    </BrowserRouter>
  </ApolloProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
