/* eslint-disable */
const initialState = {
    isOpen : false
};

const drawer = (state = initialState, action) => {
  switch (action.type) {
    case "CHANGE STATE":
      return (state = {
        isOpen : action.value
      });
    default:
      return state;
  }
};
export default drawer;
