/* eslint-disable */
const initialState = {
    display : false
};

const welcomeMessage = (state = initialState, action) => {
  switch (action.type) {
    case "CHANGE STATE":
      return (state = {
        display : action.value
      });
    default:
      return state;
  }
};
export default welcomeMessage;
