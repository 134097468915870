/* eslint-disable */
const initialState = {
  notifications: [],
  unread: 0,
  pageStart: 0,
  totalNotify: 1,
};

const setNotification = (state = initialState, action) => {
  switch (action.type) {
    case "ADD DATA":
      return (state = {
        notifications: action.notifications,
        unread: action.unread,
        pageStart: action.pageStart,
        totalNotify: action.totalNotify,
      });
    case "READ ALL DATA":
      return (state = initialState);
    default:
      return state;
  }
};
export default setNotification;
