/* eslint-disable */
const initialState = {
    change: false
};

const selection = (state = initialState, action) => {
  switch (action.type) {
    case "CHANGE SELECTION":
      return (state = {
        change: action.change
      });
    default:
      return state;
  }
};
export default selection;
